<template>
  <Top />
  <div style="background-color: rgba(246, 246, 248, 1)">
    <div class="main">
      <div class="main_top">
        <div class="tab">
          <div
            class="ordinary"
            :class="goldId == item.id ? 'act-ordinary' : 'ordinary'"
            v-for="item in types"
            :key="item.id"
            @click="check(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="main_nav">
        <div
          class="my_info"
          :style="goldId == 1 ? 'height:1.46rem' : 'height:2.72rem'"
        >
          <div class="my_top" v-if="userinfo.teamLevel == 0 ? false : true">
            <div style="display: flex">
              <div class="logo_jibie">
                <img
                  :src="
                    userinfo.teamLevel == 1
                      ? infos.imgs1
                      : userinfo.teamLevel == 2
                        ? infos.imgs2
                        : userinfo.teamLevel == 3
                          ? infos.imgs3
                          : userinfo.teamLevel == 4
                            ? infos.imgs4
                            : infos.imgs5
                  "
                  alt=""
                />
              </div>
              <div
                class="my_jibie"
                :innerText="
                  userinfo.teamLevel == 1
                    ? one
                    : userinfo.teamLevel == 2
                      ? two
                      : userinfo.teamLevel == 3
                        ? Samsung
                        : userinfo.teamLevel == 4
                          ? four
                          : five
                "
              ></div>
            </div>
            <div class="bili" v-if="goldId == 1 ? false : true">
              {{ lang.v.Commission }} {{ userinfo.rate }}%
            </div>
          </div>
          <div
            class="my_top"
            v-if="userinfo.teamLevel == 0 ? true : false"
            style="display: flex; align-items: center"
          >
            {{ lang.v.nolevel }}
          </div>
          <div class="my_foot">
            <div style="display: flex">
              <div class="fenhong_box">
                <div class="box_top">
                  <div class="stick"></div>
                  <div
                    class="title"
                    :innerText="
                      goldId == 1 ? lang.v.NumberDirectly : Community1
                    "
                  ></div>
                </div>
                <div
                  class="box_nav"
                  :innerHTML="
                    goldId == 1
                      ? userinfo.directUserNum
                      : userinfo.firstInviteMoney
                  "
                ></div>
              </div>
              <div class="fenhong_box">
                <div class="box_top">
                  <div class="stick2"></div>
                  <div
                    class="title"
                    :innerText="goldId == 1 ? lang.v.NumberStar : Promote"
                  ></div>
                </div>
                <div
                  class="box_nav"
                  :innerHTML="
                    goldId == 1
                      ? userinfo.marketUserNum
                      : userinfo.investmentDividendAmount
                  "
                ></div>
              </div>
              <div class="fenhong_box" v-if="goldId == 1 ? true : false">
                <div class="box_top">
                  <div class="stick2"></div>
                  <div class="title" :innerText="lang.v.activeMembers"></div>
                </div>
                <div class="box_nav">{{ userinfo.effFirstInviteNum }}</div>
              </div>
            </div>
            <div class="money" v-if="goldId == 1 ? false : true">
              <div class="money_text">{{ lang.v.balance2 }}</div>
              <div class="main_wallet">
                <div class="main_left">
                  <span>{{ lang.v.unit }}</span>
                  <span
                    :innerHTML="
                      goldId == 1 ? userinfo.balance : userinfo.teamBalance
                    "
                  ></span>
                </div>
                <div class="withdrawal" @click="tixian">
                  {{ lang.v.Withdraw }}
                </div>
              </div>
            </div>
            <!-- <div class="roles" v-if="goldId == 1 ? false : true">
                            <div class="main_logo"><img src="@/assets/img/main_logo.png" alt=""></div>
                            <div class="main_text">Commission sharing rules</div>
                        </div> -->
          </div>
        </div>
        <div class="lists">
          <el-table :data="lists" style="width: 100%" :empty-text="lang.v.none">
            <el-table-column
              prop="userId"
              align="center"
              v-if="goldId == 1 ? true : false"
              label="UID"
              width="50"
            >
              <template #default="scope">
                <div class="type" :innerHTML="scope.row.userId"></div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="type"
              width="60"
              v-if="goldId != 1"
            >
              <template #default="scope">
                <div class="type">{{ scope.row.typeStr }}</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="goldId == 1 ? type : amount"
              width="60"
              v-if="goldId == 1 ? true : false"
            >
              <template #default="scope">
                <div
                  class="type"
                  :innerHTML="
                    scope.row.type != null
                      ? scope.row.type == 1
                        ? lang.v.experienceStar
                        : lang.v.ExclusiveStar
                      : lang.v.noCard
                  "
                ></div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="amount" v-if="goldId != 1">
              <template #default="scope">
                <div
                  class="type"
                  :innerHTML="lang.v.unit + scope.row.amount || 0"
                ></div>
              </template>
            </el-table-column>
            <el-table-column
              width="50"
              prop="level"
              align="center"
              v-if="goldId == 1 ? true : false"
              :label="lang.v.StarClusterLevel"
            >
              <template #default="scope">
                <div class="type" :innerHTML="scope.row.teamLevel"></div>
              </template>
            </el-table-column>
            <el-table-column
              width="55"
              prop="level"
              align="center"
              v-if="goldId == 1 ? true : false"
              :label="lang.v.PersonalPerformance"
            >
              <template #default="scope">
                <div class="type">
                  {{ formatMoney(scope.row.personMoney) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="50"
              prop="level"
              align="center"
              v-if="goldId == 1 ? true : false"
              :label="lang.v.StarClusterPerformance"
            >
              <template #default="scope">
                <div class="type">
                  {{ formatMoney(scope.row.teamMoney) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              prop="level"
              align="center"
              v-if="goldId == 1 ? true : false"
              :label="lang.v.memberNumber"
            >
              <template #default="scope">
                <div class="type" :innerHTML="scope.row.talentCardNum"></div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              v-if="goldId != 1"
              :label="lang.v.time"
            >
              <template #default="scope">
                <div :innerHTML="scope.row.time"></div>
              </template>
            </el-table-column>
          </el-table>
          <van-loading size="0.24rem" v-if="loading">{{
            lang.v.load
          }}</van-loading>
          <div class="list_more" v-if="more" @click="loadMore">
            <div class="text">{{ lang.v.loadmore }}</div>
            <div class="more_logo">
              <img src="@/assets/img/down-org.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Tabbar />
</template>
<script setup>
import { onMounted, reactive, ref } from 'vue';
import { personal, getCommunityList, income, in_wallet } from '@/api/fetcher';
import Top from '@/components/Top/top.vue';
import Tabbar from '@/components/Tabbar/tabbar.vue';
import message from '@/utils/message';
import { lang } from '@/lang';
import dataCenter from '@/dataCenter';
const five = lang.v.five;
const four = lang.v.four;
const Samsung = lang.v.Samsun;
const two = lang.v.two;
const one = lang.v.one;
const Community1 = lang.v.Community1;
const Promote = lang.v.Promote;
const type = lang.v.type;
const amount = lang.v.amount;
const title_6 = lang.v.title_6;
const title_8 = lang.v.title_8;
let goldId = ref('1');
const no_more = ref(false);
const more = ref(false);
const types = reactive([
  {
    id: 1,
    name: title_6,
  },
  {
    id: 2,
    name: title_8,
  },
]);

//计算
const formatMoney = money => {
  return Number(money).toFixed(2) | 0;
};
const check = async item => {
  goldId.value = item.id;
  if (goldId.value == 2) {
    await income({
      page: pages.page,
      limit: pages.limit,
      team: 1,
    }).then(res => {
      lists.value = res?.list;
      console.log(res);
      if (lists.value.length >= pages.limit) {
        more.value = true;
        no_more.value = false;
      } else {
        more.value = false;
        if (lists.value.length == 0) {
          no_more.value = true;
        }
      }
    });
  }
  if (goldId.value == 1) {
    await getCommunityList({
      page: pages.page,
      limit: pages.limit,
    }).then(res => {
      lists.value = res?.list;
      if (lists.value.length >= pages.limit) {
        more.value = true;
        no_more.value = false;
      } else {
        more.value = false;
        if (lists.value.length == 0) {
          no_more.value = true;
        }
      }
    });
  }
};
const success2 = lang.v.success2;
const tixian = async () => {
  try {
    await in_wallet({
      amount: userinfo.value.teamBalance,
      type: 3,
    }).then(async () => {
      const userData = await personal().then(res => res);
      dataCenter.user.save(userData);
      userinfo.value = userData;
      message.success(success2);
    });
  } catch {
    console.log('err');
  }
};
const infos = {
  imgs1: require('@/assets/img/logo_1.png'),
  imgs2: require('@/assets/img/logo_2.png'),
  imgs3: require('@/assets/img/logo_3.png'),
  imgs4: require('@/assets/img/logo_4.png'),
  imgs5: require('@/assets/img/logo_5.png'),
};
const userinfo = ref(JSON.parse(localStorage.getItem('userInfo')));
const lists = ref([]);
const pages = reactive({
  page: 1,
  limit: 6,
});
// 加载更多
const loading = ref(false);
const loadMore = async () => {
  loading.value = true;
  setTimeout(async () => {
    if (goldId.value == 1) {
      await getCommunityList({
        page: pages.page + 1,
        limit: pages.limit,
      }).then(res => {
        loading.value = false;
        const data = res;
        if (data.list.length >= pages.limit) {
          more.value = true;
          pages.page++;
        } else {
          pages.page++;
          more.value = false;
        }
        const temp = [];
        data.list.forEach(v => {
          temp.push(v);
        });
        lists.value = [...lists.value, ...temp];
      });
    }
    if (goldId.value == 2) {
      await income({
        page: pages.page + 1,
        limit: pages.limit,
        team: 1,
      }).then(res => {
        loading.value = false;
        const data = res;
        if (data.list.length >= pages.limit) {
          more.value = true;
        } else {
          pages.page++;
          more.value = false;
        }
        const temp = [];
        data.list.forEach((v, i) => {
          temp.push(v);
        });
        lists.value = [...lists.value, ...temp];
      });
    }
  }, 500);
};
onMounted(async () => {
  await personal().then(res => {
    userinfo.value = res;
    window.localStorage.setItem('userInfo', JSON.stringify(res));
  });
  try {
    const userInfoString = localStorage.getItem('userInfo');
    if (userInfoString && userInfoString !== 'undefined') {
      userinfo.value = JSON.parse(userInfoString);
    }
  } catch (e) {
    console.error('Error parsing userInfo from localStorage:', e);
  }
  await getCommunityList({
    page: pages.page,
    limit: pages.limit,
  }).then(res => {
    lists.value = res?.list;
    console.log(lists.value.length);
    if (lists.value.length >= pages.limit) {
      more.value = true;
      no_more.value = false;
    } else {
      more.value = false;
      if (lists.value.length == 0) {
        no_more.value = true;
      }
    }
  });
});
</script>
<style lang="less" scoped>
.main {
  width: 92.53%;
  margin: auto;

  .main_top {
    width: 100%;
    margin-top: 0.2rem;
    margin-bottom: 0.04rem;

    .tab {
      width: 80.62%;
      height: 0.32rem;
      background: rgba(133, 140, 159, 0.06);
      margin: auto;
      border-radius: 0.06rem;
      display: flex;
      justify-content: space-between;

      .ordinary {
        //       overflow: hidden; /* 隐藏溢出的内容 */
        // text-overflow: ellipsis; /* 显示省略号 */
        white-space: nowrap;
        width: 55.71%;
        height: 0.32rem;
        text-align: center;
        line-height: 0.3rem;
        font-size: 0.13rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
      }

      .act-ordinary {
        width: 55.71%;
        height: 0.3rem;
        text-align: center;
        line-height: 0.3rem;
        font-size: 0.13rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #444444;
        background: #ffffff;
        box-shadow: 0rem 0rem 0.08rem 0rem rgba(133, 140, 159, 0.15);
        border-radius: 0.04rem;
      }
    }
  }

  .main_nav {
    width: 100%;

    .my_info {
      width: 100%;
      margin-top: 0.14rem;
      background: linear-gradient(180deg, #fff9ec 0%, #ffffff 72%) #ffffff;
      border-radius: 0.06rem;

      .my_top {
        width: 91.93%;
        height: 0.5rem;
        margin: auto;
        display: flex;
        justify-content: space-between;
        border-bottom: 0.01rem solid rgba(133, 140, 159, 0.2);

        .logo_jibie {
          width: 0.22rem;
          height: 0.22rem;
          padding-top: 0.14rem;

          img {
            width: 100%;
          }
        }

        .my_jibie {
          margin-top: 0.14rem;
          margin-left: 0.06rem;
          height: 0.22rem;
          font-size: 0.16rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #444444;
          line-height: 0.22rem;
        }

        .bili {
          height: 0.22rem;
          background: #ffffff;
          border-radius: 0.2rem;
          opacity: 1;
          font-size: 0.13rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ed931e;
          line-height: 0.22rem;
          text-align: center;
          padding-left: 0.1rem;
          padding-right: 0.1rem;
          margin-top: 0.14rem;
          border: 0.01rem solid #ed931e;
        }
      }

      .my_foot {
        width: 100%;
        margin: auto;
        height: 0.2rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
        line-height: 0.22rem;
        margin-top: 0.14rem;

        .fenhong_box {
          margin-left: 0.24rem;

          .box_top {
            display: flex;

            .stick {
              margin-top: 0.05rem;
              width: 0.02rem;
              height: 0.12rem;
              background: #5eb15c;
              border-radius: 0.06rem;
              opacity: 1;
            }

            .stick2 {
              margin-top: 0.05rem;
              width: 0.02rem;
              height: 0.12rem;
              background: rgba(94, 177, 92, 0.5);
              border-radius: 0.06rem;
              opacity: 1;
            }

            .stick3 {
              margin-top: 0.05rem;
              width: 0.02rem;
              height: 0.12rem;
              background: rgba(91, 176, 255, 1);
              border-radius: 0.06rem;
              opacity: 1;
            }

            .title {
              height: 0.42rem;
              font-size: 0.12rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #858c9f;
              line-height: 0.15rem;
              margin-left: 0.06rem;
            }
          }

          .box_nav {
            height: 0.22rem;
            font-size: 0.16rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #444444;
            line-height: 0.22rem;
            margin-top: 0.06rem;
            margin-left: 0.14rem;
          }
        }

        .fenhong_box:nth-child(1) {
          margin-left: 0.14rem;
        }

        .money {
          width: 91.93%;
          margin: auto;
          margin-top: 0.14rem;
          border-bottom: 0.01rem solid rgba(133, 140, 159, 0.2);

          .main_wallet {
            width: 100%;
            display: flex;
            margin: auto;
            justify-content: space-between;
            margin-top: 0.12rem;
            padding-bottom: 0.14rem;

            .main_left {
              height: 0.21rem;
              font-size: 0.16rem;
              font-family: MiSans, MiSans;
              font-weight: 600;
              color: #444444;
              line-height: 0.21rem;
              margin-top: -0.04rem;

              span:nth-child(2) {
                height: 0.42rem;
                font-size: 0.32rem;
                font-family: MiSans, MiSans;
                font-weight: 600;
                color: #444444;
                line-height: 0.42rem;
              }
            }

            .withdrawal {
              width: 1.5rem;
              height: 0.34rem;
              background: #ed931e;
              border-radius: 0.61rem;
              opacity: 1;
              font-size: 0.14rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 0.34rem;
              text-align: center;
            }
          }
        }

        .roles {
          width: 1.06rem;
          height: 0.36rem;
          display: flex;
          background-color: rgba(133, 140, 159, 0.1);
          border-radius: 0.06rem;
          margin-left: 0.14rem;
          margin-top: 0.12rem;

          .main_logo {
            width: 0.2rem;
            height: 0.2rem;

            img {
              width: 100%;
              margin-top: 0.08rem;
              margin-left: 0.12rem;
            }
          }

          .main_text {
            font-size: 0.14rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #858c9f;
            line-height: 0.36rem;
            margin-left: 0.16rem;
          }
        }
      }
    }

    .lists {
      width: 100%;
      border-radius: 0.06rem;
      margin-top: 0.16rem;
      background-color: rgba(255, 255, 255, 1);
      position: relative;

      .list_head {
        width: 92.5%;
        display: flex;
        height: 0.5rem;
        margin: auto;
        border-bottom: 1px solid rgba(133, 140, 159, 0.2);

        .head {
          width: 1rem;
          height: 0.5rem;
          line-height: 0.5rem;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #858c9f;
          flex-shrink: 0;
        }

        .head:nth-child(2) {
          width: 0.8rem;
        }

        .head:nth-child(3) {
          width: 1.5rem;
        }
      }

      .list_main {
        width: 92.5%;
        display: flex;
        margin: auto;
        height: 0.4rem;
        .head {
          width: 1rem;
          height: 0.4rem;
          line-height: 0.4rem;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #858c9f;
          flex-shrink: 0;
        }

        .head:nth-child(2) {
          width: 0.8rem;
          padding-right: 1rem;
        }

        .head:nth-child(3) {
          width: 1.5rem;
        }
      }

      .no_text {
        text-align: center;
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #858c9f;
        line-height: 0.2rem;
        margin-top: 0.06rem;
      }

      .list_more {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 0.06rem;
        padding-bottom: 0.06rem;

        .text {
          height: 0.2rem;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #444444;
          line-height: 0.2rem;
        }

        .more_logo {
          width: 0.2rem;
          height: 0.2rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

:deep(.van-loading) {
  text-align: center;
}
/* 移除单元格的左右内边距 */
:deep(.el-table .cell) {
  padding-left: 0;
  padding-right: 0;
  font-size: 0.1rem;
  line-height: 0.15rem;
}
:deep(.el-table__empty-text) {
  line-height: 0.2rem;
  width: 100%;
}
.type {
  font-size: 0.12rem;
}
</style>
